import { createContext, createRef, useMemo, useState } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Button, Theme, useMediaQuery } from '@mui/material';

import { KBarSnackBar } from '/common/Kbar/KBarSnackBar';
import { FCWithChildren } from '/common/models';

import SnackbarContainer from './SnackbarContainer';
import { ISnackbarCustomContext } from './types';

const notistackRef = createRef<SnackbarProvider>();

const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const actionButton = (key: SnackbarKey) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>;

export const SnackbarCustomContext = createContext({} as ISnackbarCustomContext);

export const Snackbar: FCWithChildren = ({ children }) => {
  const [areDuplicationsPrevented, setAreDuplicationsPrevented] = useState(false);

  const snackbarCustomContextValue = useMemo<ISnackbarCustomContext>(() => ({ setAreDuplicationsPrevented }), []);
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <SnackbarCustomContext.Provider value={snackbarCustomContextValue}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: mobile ? 'top' : 'bottom',
          horizontal: 'right',
        }}
        preventDuplicate={areDuplicationsPrevented}
        ref={notistackRef}
        action={actionButton}
        maxSnack={5}
        //Each component should be declared in VariantOverrides => ./types.ts
        Components={{ kBarSnackBar: KBarSnackBar }}
      >
        <SnackbarContainer>{children}</SnackbarContainer>
      </SnackbarProvider>
    </SnackbarCustomContext.Provider>
  );
};
