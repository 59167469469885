import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { Environment, FlowID, UserID } from '@playq/octopus-common';
import { AppTeamTemplateId, TeamTemplateId } from '@playq/services-shared';
import { BlueprintEntityID } from '@playq/octopus2-analytics';
import { MatchConfigID } from '@playq/services-rush';
import { CouponID, CouponTemplateID, ItemID, PackageID } from '@playq/services-bookkeeper';
import { GameEventID } from '@playq/octopus2-liveops';
import { App } from '@playq/octopus2-apps';
import { AppLadderId } from '@playq/services-clerk';
import { SynapseProblemID } from '@playq/octopus2-ml';
import { CopyEntityID } from '@playq/octopus2-creatives';
import { CloudFunctionID } from '@playq/octopus2-cloud';
import { ScriptID } from '@playq/octopus2-scripts';
import { FileFeature, FileID } from '@playq/octopus2-files';
import { GroupID, WebEventID } from '@playq/octopus2-admin';
import { SchedulingId } from '@playq/services-jobot-scheduler';
import { CredentialsID } from '@playq/octopus2-auth';

import { pageLink, pageTitleKey, pageTitleStorage } from '/storage/sessionStorage/currentModule';
import { matchGetKeys } from '/api/hooks/matchesService';
import { leaderboardGetKeys } from '/api/hooks/leaderboardsService';
import { appsInventoryService } from '/api/hooks/appsInventoryService/constants';
import {
  appsInventoryRetrieveKeys,
  appsPackageRetrieveKeys,
  cloudFunctionRetrieveKeys,
  copyQueryTags,
  fileGetKeys,
  flowsRetrieveQueryKeys,
  gameEventsQueryKeys,
  groupGetKeys,
  integrationGetKeys,
  problemRetrieveQueryKeys,
  retrieveCredentialsKeys,
  scriptsRetrieveKeys,
  services2,
  templateRetrieveSupportQueryKeys,
  unwrapEither,
  useExperimentRetrieve,
  userGetKeys,
  webEventGetKeys,
} from '/api';
import { appToolkit, authToolkit } from '/store';
import { history } from '/Router/history';
import { log } from '/helpers';
import {
  ANALYTICS_BLUEPRINTS,
  ANALYTICS_EXPERIMENTS,
  CONFIGURATION,
  CORP_CREDENTIALS,
  CORP_GROUPS,
  CORP_INTEGRATIONS,
  CORP_USERS,
  CORP_WEB_EVENTS,
  ECONOMY_COUPONS_MASS,
  ECONOMY_COUPONS_TARGETED,
  ECONOMY_INVENTORY,
  ECONOMY_PACKAGES,
  FILES,
  LIVE_OPS_EVENTS,
  LIVE_OPS_FLOWS,
  ML_SYNAPSE,
  SERVICES_CLOUD_FUNCTIONS,
  SERVICES_SCRIPTS,
  SOCIAL_LEADERBOARDS,
  SOCIAL_MATCHES,
  SOCIAL_TEAMS,
  SUPPORT_TEAMS_LOOKUP,
  UA_COPY,
} from '/common/PageTitle/constants';
import { appsPackagesService } from '/api/hooks/appsPackagesService/constants';
import { CouponType } from '/component/Economy/Coupons/types';
import { couponGetKeys } from '/api/hooks/couponsService';
import { teamGetKeys } from '/api/hooks/teamsService';
import { blueprintsService, blueprintsServiceName } from '/api/hooks/blueprintsService';

import { capitalizeFirstLetter, parsePath } from './helpers';

const emptyApp = new App();

export const PageTitle = () => {
  const queryClient = useQueryClient();
  const app = useSelector(appToolkit.selectors.app) || emptyApp;
  const [entityName, setEntityName] = useState<string | undefined>();
  const [env, setEnv] = useState<string | undefined>();
  const [entityId, setEntityId] = useState<string | undefined>();
  const [currentPath, setCurrentPath] = useState<string | undefined>();
  const [currentModule, setCurrentModule] = useState<string | undefined>();
  const companyId = useSelector(authToolkit.selectors.companyID);
  const company = useSelector(authToolkit.selectors.company);

  const { retrieveExperiment } = useExperimentRetrieve({
    id: entityId ? +entityId : undefined,
    onSuccess: (res) => setEntityName(_.get(res, 'experiment.name')),
    onError: (err) => log('Retrieve experiment', err),
  });

  // Setting title by current path
  const getStaticTitle = (): string => {
    if (__CI_BRANCH__ === 'develop') {
      return 'DEV Portal';
    }

    if (__CI_BRANCH__ === 'staging') {
      return 'STG Portal';
    }

    return 'Portal';
  };

  const convertTitle = (title: string): string => {
    if (title.toLowerCase().includes(CONFIGURATION)) {
      return 'Configuration';
    }
    return capitalizeFirstLetter(title);
  };

  const setTitle = (title: string): void => {
    const convertedTitle = convertTitle(title);
    const extendedTitle = `${title ? convertedTitle + ' | ' : ''}`;
    document.title = `${extendedTitle} ${getStaticTitle()}`;
    pageTitleStorage.set(pageTitleKey, convertedTitle);
    pageTitleStorage.set(pageLink, currentPath ?? window.location.href);
  };

  const setTitleByPath = (path: string) => {
    setCurrentPath(path);
    setTitle(parsePath(path, entityName, setEntityId, setCurrentModule, setEnv));
  };

  useEffect(() => {
    setTitleByPath(window.location.href);

    const unlisten = history.listen((location) => {
      setEntityName(undefined);
      setEntityId(undefined);
      setTitleByPath(location.pathname);
    });

    return () => {
      unlisten();
    };
  });

  // Getting enity name by id & module info
  const defineEntityName = useCallback(
    <LeftData, RightData>(
      queryKeys: QueryKey,
      responseKeys: string,
      errorLogInfo: string,
      queryFn: () => Promise<RightData | LeftData>
    ) => {
      queryClient
        .fetchQuery(queryKeys, queryFn)
        .then((res) => setEntityName(_.get(res, responseKeys)))
        .catch((err) => log(errorLogInfo, err));
    },
    [queryClient]
  );

  //Controller for global modules
  useEffect(() => {
    if (company === undefined || companyId == undefined || entityId === undefined || currentModule === undefined) {
      return;
    }

    switch (currentModule) {
      case CORP_CREDENTIALS: {
        const id = new CredentialsID();
        id.id = +entityId;
        id.company = companyId;

        defineEntityName(retrieveCredentialsKeys.concat(id), 'name', 'Retrieve credentials', () =>
          unwrapEither(services2.adminCredentialsManagementService.retrieve(id))
        );
        break;
      }
      case CORP_WEB_EVENTS: {
        const id = new WebEventID();
        id.id = +entityId;
        id.company = companyId;

        defineEntityName(webEventGetKeys.concat(id), 'name', 'Retrieve integration', () =>
          unwrapEither(services2.webEventsService.retrieve(id))
        );
        break;
      }
      case CORP_INTEGRATIONS: {
        const id = new SchedulingId();
        id.id = entityId;
        id.company = company.servicesId.id;

        defineEntityName(integrationGetKeys.concat(id), 'name', 'Retrieve integration', () =>
          unwrapEither(services2.integrationsService.retrieve(id))
        );
        break;
      }
      case CORP_GROUPS: {
        const id = new GroupID();
        id.id = +entityId;
        id.company = companyId;

        defineEntityName(groupGetKeys.concat(id), 'name', 'Retrieve group', () =>
          unwrapEither(services2.adminGroupsService.retrieve(id))
        );
        break;
      }
      case CORP_USERS: {
        const id = new UserID();
        id.id = +entityId;
        id.company = companyId;

        defineEntityName(userGetKeys.concat(id), 'name', 'Retrieve user', () =>
          unwrapEither(services2.adminUsersService.retrieve(id))
        );
        break;
      }
      case FILES: {
        const id = new FileID();
        id.company = companyId;
        id.id = +entityId;

        defineEntityName(fileGetKeys.concat(id), 'file.name', 'Retrieve file', () =>
          unwrapEither(
            services2.filesService.getFile(id, [FileFeature.Revisions, FileFeature.Tags, FileFeature.Thumbnail])
          )
        );
        break;
      }
    }
    //In this case, useEffect using for  updating EntityName,
    // other deps create memory leak
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, companyId, currentModule, currentPath]);

  // Controller for app modules
  useEffect(() => {
    if (app === undefined || app.id === undefined || entityId === undefined || currentModule === undefined) {
      return;
    }

    switch (currentModule) {
      case SUPPORT_TEAMS_LOOKUP: {
        const localENV = env === Environment.Sandbox ? Environment.Sandbox : Environment.Live;
        const templateId = new TeamTemplateId();
        templateId.id = entityId;

        defineEntityName(templateRetrieveSupportQueryKeys.concat(templateId), 'name', 'Retrieve team template', () =>
          unwrapEither(services2.teamsLookupService.getTemplate(app.id, templateId, localENV))
        );
        break;
      }
      case SERVICES_SCRIPTS: {
        const id = new ScriptID();
        id.app = app.id.id;
        id.id = +entityId;

        defineEntityName(scriptsRetrieveKeys.concat(id), 'script.name', 'Retrieve script', () =>
          unwrapEither(services2.scriptsService.retrieve(id, undefined))
        );
        break;
      }
      case SERVICES_CLOUD_FUNCTIONS: {
        const id = new CloudFunctionID();
        id.id = +entityId;
        id.app = app.id.id;

        defineEntityName(cloudFunctionRetrieveKeys.concat(id), 'cloudFunction.name', 'Retrieve cloud function', () =>
          unwrapEither(services2.cloudFunctionsService.retrieve(id, undefined))
        );
        break;
      }
      case UA_COPY: {
        const id = new CopyEntityID();
        id.app = app.id.id;
        id.id = +entityId;

        defineEntityName(copyQueryTags.concat(id), 'entity.name', 'Retrieve creative copy', () =>
          unwrapEither(services2.copiesService.retrieve(id))
        );
        break;
      }
      case ML_SYNAPSE: {
        const id = new SynapseProblemID();
        id.app = app.id.id;
        id.id = +entityId;

        defineEntityName(problemRetrieveQueryKeys.concat(id), 'problem.name', 'Retrieve synapse', () =>
          unwrapEither(services2.synapseProblemsService.retrieve(id, undefined))
        );

        break;
      }
      case ANALYTICS_BLUEPRINTS: {
        const id = new BlueprintEntityID();
        const version = undefined;
        id.app = app.id.id;
        id.id = +entityId;

        defineEntityName(
          [blueprintsServiceName, 'retrieve', id.app, id.id, version],
          'blueprint.name',
          'Retrieve blueprint',
          () => unwrapEither(blueprintsService.retrieve(id, version))
        );

        break;
      }
      case ANALYTICS_EXPERIMENTS: {
        retrieveExperiment({ id: +entityId });
        break;
      }
      case SOCIAL_TEAMS: {
        const id = new AppTeamTemplateId();
        id.id = entityId;
        id.app = app.fingerprintID.app;

        defineEntityName(teamGetKeys.concat(id), 'team.name', 'Retrieve team', () =>
          unwrapEither(services2.teamsService.get(id))
        );
        break;
      }
      case SOCIAL_MATCHES: {
        const id = new MatchConfigID();
        id.id = entityId;
        id.app = app.fingerprintID.app;

        defineEntityName(matchGetKeys.concat(id), 'name', 'Retrieve match', () =>
          unwrapEither(services2.matchesService.get(id))
        );
        break;
      }
      case SOCIAL_LEADERBOARDS: {
        const id = new AppLadderId();
        id.ladderId = entityId;
        id.appId = app.fingerprintID.app;

        defineEntityName(leaderboardGetKeys.concat(id), 'name', 'Retrieve leaderboard', () =>
          unwrapEither(services2.lboardsService.getLadder(id))
        );
        break;
      }
      case ECONOMY_COUPONS_TARGETED: {
        const id = new CouponTemplateID();
        id.app = app.fingerprintID.app;
        id.id = entityId;

        defineEntityName(couponGetKeys[CouponType.targeted].concat(id), 'coupon.data.name', 'Retrieve coupon', () =>
          unwrapEither(services2.targetedCouponsService.get(id))
        );
        break;
      }
      case ECONOMY_COUPONS_MASS: {
        const id = new CouponID();
        id.app = app.fingerprintID.app;
        id.id = entityId;

        defineEntityName(couponGetKeys[CouponType.mass].concat(id), 'coupon.name', 'Retrieve coupon', () =>
          unwrapEither(services2.massCouponsService.get(id))
        );
        break;
      }
      case ECONOMY_INVENTORY: {
        const id = new ItemID();
        id.app = app.fingerprintID.app;
        id.id = entityId;

        defineEntityName(appsInventoryRetrieveKeys.concat(id), 'content.name', 'Retrieve inventory item', () =>
          unwrapEither(appsInventoryService.retrieve(id, undefined))
        );
        break;
      }
      case ECONOMY_PACKAGES: {
        const id = new PackageID();
        id.app = app.fingerprintID.app;
        id.id = entityId;

        defineEntityName(appsPackageRetrieveKeys.concat(id), 'pkg.content.name', 'Retrieve package', () =>
          unwrapEither(appsPackagesService.getPackage(id))
        );
        break;
      }
      case LIVE_OPS_EVENTS: {
        const id = new GameEventID();
        id.app = app.id.id;
        id.id = +entityId;

        defineEntityName(gameEventsQueryKeys.concat(id), 'event.name', 'Retrieve game event', () =>
          unwrapEither(services2.appGameEventsService.retrieve(id, undefined))
        );
        break;
      }
      case LIVE_OPS_FLOWS: {
        const id = new FlowID();
        id.id = +entityId;
        id.app = app.id.id;

        defineEntityName(flowsRetrieveQueryKeys.concat(id), 'flow.name', 'Retrieve flow', () =>
          unwrapEither(services2.flowsService.retrieve(id, undefined))
        );

        break;
      }
    }
    //In this case, useEffect using for  updating EntityName,
    // other deps create memory leak
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, app, currentModule, currentPath]);

  // Return nothing, component use only for managing document.title value
  return null;
};
