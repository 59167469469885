import { GameEventWithVersion, FlowWithVersion, GameEventBrief, FlowBrief } from '@playq/octopus2-liveops';
import { ExperimentWithVersion, ExperimentBrief } from '@playq/octopus2-analytics';
import { AppEntityClass } from '@playq/octopus2-apps';
import { AppSpacePromotions } from '@playq/octopus2-apps-utility';

import { SpacePromotionTableItem } from '../types';

export const getSpacePromotionFromEntityWithVersion = (
  entity: FlowWithVersion | GameEventWithVersion | ExperimentWithVersion,
  entitiesBrief: (GameEventBrief | FlowBrief | ExperimentBrief)[]
): SpacePromotionTableItem => ({
  ...entity.serialize(),
  className: entity.getClassName(),
  urlPathId: String(entity.id.id),
  name: entitiesBrief.find(({ id }) => id.serialize() === entity.id.serialize())?.name ?? entity.id.serialize(),
});

export const getPromotionsInitialValues = () => ({
  config: undefined,
  segment: undefined,
  experiments: [],
  events: [],
  flows: [],
});

export const getAvailableEntitiesToCopy = (promotions?: AppSpacePromotions) => {
  const result: AppEntityClass[] = [];

  const { config, segment, experiments, flows, events } = promotions ?? getPromotionsInitialValues();

  if (config !== undefined) {
    result.push(AppEntityClass.Config);
  }
  if (segment !== undefined) {
    result.push(AppEntityClass.Segmentation);
  }
  if (experiments.length > 0) {
    result.push(AppEntityClass.Experiment);
  }
  if (events.length > 0) {
    result.push(AppEntityClass.GameEvent);
  }
  if (flows.length > 0) {
    result.push(AppEntityClass.Flow);
  }

  return result;
};
