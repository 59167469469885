import { Tooltip, TooltipProps } from '@mui/material';
import { useTheme } from '@mui/styles';

export const CustomTooltip = (props: TooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          },
        },
      }}
    />
  );
};
